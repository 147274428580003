
const state = {
    // Local
    // apiURL: 'http://localhost:8000/api/v1',
    // serverPath: 'http://localhost:8000',
    // stripePub: 'pk_test_51JItqvC16s91wavZJODDDB2t85BQEfshN4KM3lwELnnDJjK9v6fB2exSNktTUatybfz02uDc3YCHkBEXvZ0I2HiI00WaSxvCTz',
    // lastReleasedFrontEnd: '2022-03-19',
    // lastReleasedBackendEnd: '2022-03-19',
    // cyKey: '20220-05-20'

    // Testing - Samath
    // apiURL:'http://api-cvscreen.samath.com.au/api/v1',
    // serverPath:'http://api-cvscreen.samath.com.au',
    // stripePub:'pk_test_51JItqvC16s91wavZJODDDB2t85BQEfshN4KM3lwELnnDJjK9v6fB2exSNktTUatybfz02uDc3YCHkBEXvZ0I2HiI00WaSxvCTz',
    // lastReleasedFrontEnd:'2023-12-27',
    // lastReleasedBackendEnd:'2023-12-27',
    // cyKey:'2023-12-27'

    // Production
    apiURL:'https://api.cvscreen.com.au/api/v1',
    serverPath:'https://api.cvscreen.com.au',
    stripePub:'pk_live_51JItqvC16s91wavZQAHHjRF6ipGcy6TPi7qXgwblBaq6tchP2AtJHx62vHP4K7DBOnAIzjWAacL8yMpeCaz3OdDY00AdR7CesZ',
    lastReleasedFrontEnd:'20223-06-16',
    lastReleasedBackendEnd:'20223-06-16',
    cyKey:'20225-03-02'
};

const getters = {
    //
};

const actions = {

};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations,
}
